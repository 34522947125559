/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/react-in-jsx-scope */
import "../styles/main.scss";
import "../styles/style.scss";
import { ApolloProvider } from "@apollo/client";
import { useEffect } from "react";
import Router, { useRouter } from "next/router";
import NProgress from "nprogress";
import { ThemeProvider } from "@mui/material/styles";
import { AppProvider } from "../components/context/AppContext";
import { AuthContextProvider } from "../components/context/auth-context";
import client from "../components/ApolloClient";
import theme from "../styles/theme";
import * as fbq from "../../libs/fpixel";
//import { GoogleAnalytics } from "nextjs-google-analytics";

NProgress.configure({ showSpinner: false });
Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

function MyApp({ Component, pageProps }) {
  const router = useRouter();

  useEffect(() => {
    // This pageview only triggers the first time (it's important for Pixel to have real information)
    fbq.pageview();

    const handleRouteChange = () => {
      fbq.pageview();
    };

    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <ApolloProvider client={client}>
      <AppProvider>
        <AuthContextProvider>
          <ThemeProvider theme={theme}>
            <Component {...pageProps} />
          </ThemeProvider>
        </AuthContextProvider>
      </AppProvider>
    </ApolloProvider >
  );
}

export default MyApp;

